@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;600&family=Roboto:wght@100;300;400;500;700&display=swap";
:root {
  --primary-green: #2f6969;
  --primary-dark-1: #02435f;
  --primary-dark-2: #7f807d;
  --primary-dark-3: #b0b0b5;
  --primary-dark-4: #e2e2e2;
  --primary-dark-5: #ed8b00;
  --background-white: #fff;
  --background-gray: #fafafa;
  --background-divider: #f0f0f0;
  --secondary-blue-green: #1e4949;
  --secondary-gray: #323f48;
  --secondary-blue: #0a90d6;
  --secondary-green-hue: #ebf6f0;
  --secondary-success: #77b20f;
  --secondary-attention: #d60000;
}

html {
  width: 100%;
  min-height: 100vh;
  overflow-y: overlay;
  flex-direction: column;
  display: flex;
}

body {
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  display: flex;
}

body #app {
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  display: flex;
  position: relative;
}

body #app:empty {
  min-height: 100vh;
  color: var(--primary-dark-1);
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  position: relative;
}

body #app:empty:before {
  content: "Loading Affiliate Dashboard secure session, this will only take a few seconds...";
  text-align: center;
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
}

.primary-green {
  color: var(--primary-green);
}

.primary-dark-1 {
  color: var(--primary-dark-1);
}

.primary-dark-2 {
  color: var(--primary-dark-2);
}

.primary-dark-3 {
  color: var(--primary-dark-3);
}

.primary-dark-4 {
  color: var(--primary-dark-4);
}

.primary-dark-5 {
  color: var(--primary-dark-5);
}

.background-white {
  color: var(--background-white);
}

.background-gray {
  color: var(--background-gray);
}

.background-divider {
  color: var(--background-divider);
}

.secondary-blue-green {
  color: var(--secondary-blue-green);
}

.secondary-blue {
  color: var(--secondary-blue);
}

.secondary-green-hue {
  color: var(--secondary-green-hue);
}

.large-type {
  letter-spacing: -.01em;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 100;
  line-height: 54px;
}

.h1-headline-regular {
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
}

.h1-headline-light {
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 42px;
}

.h2-headline {
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
}

.h2-mono {
  letter-spacing: .05em;
  font-family: Roboto Mono;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
}

.body-large-light {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}

.body-large-regular {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.body-large-semibold {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.body-standard-regular {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.body-standard-semibold {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.body-small-regular {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.body-small-semibold {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.label-small-allcaps {
  letter-spacing: .03em;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.label-mono {
  letter-spacing: .1em;
  font-family: Roboto Mono;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.label-xsmall {
  letter-spacing: .03em;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}

.label-xsmall-allcaps {
  letter-spacing: .03em;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
}
/*# sourceMappingURL=index.e5d00091.css.map */
