@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;500;600&family=Roboto:wght@100;300;400;500;700&display=swap");
:root {
   --primary-green: #2F6969;
   --primary-dark-1: #02435f;
   --primary-dark-2: #7f807d;
   --primary-dark-3: #b0b0b5;
   --primary-dark-4: #e2e2e2;
   --primary-dark-5: #ED8B00;
   --background-white: #ffffff;
   --background-gray: #fafafa;
   --background-divider: #f0f0f0;
   --secondary-blue-green: #1e4949;
   --secondary-gray: #323f48;
   --secondary-blue: #0a90d6;
   --secondary-green-hue: #ebf6f0;
   --secondary-success: #77b20f;
   --secondary-attention: #d60000;
}

html {
   display: flex;
   min-height: 100vh;
   flex-direction: column;
   width: 100%;
   overflow-y: overlay;
}

body {
   display: flex;
   flex-direction: column;
   min-height: 100vh;
   margin: 0px;

   #app {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
      flex: 1;
      position: relative;
   }

   #app:empty {
      display: flex;
      min-height: 100vh;
      flex-direction: column;
      flex: 1;
      position: relative;
      color: var(--primary-dark-1);
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;
   }

   #app:empty:before {
      content: "Loading Affiliate Dashboard secure session, this will only take a few seconds...";
      text-align: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 26px;
      line-height: 36px;
   }
}

.primary-green {
   color: var(--primary-green);
}

.primary-dark-1 {
   color: var(--primary-dark-1);
}

.primary-dark-2 {
   color: var(--primary-dark-2);
}

.primary-dark-3 {
   color: var(--primary-dark-3);
}

.primary-dark-4 {
   color: var(--primary-dark-4);
}

.primary-dark-5 {
   color: var(--primary-dark-5);
}

.background-white {
   color: var(--background-white);
}

.background-gray {
   color: var(--background-gray);
}

.background-divider {
   color: var(--background-divider);
}

.secondary-blue-green {
   color: var(--secondary-blue-green);
}

.secondary-blue {
   color: var(--secondary-blue);
}

.secondary-green-hue {
   color: var(--secondary-green-hue);
}

.large-type {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 100;
   font-size: 48px;
   line-height: 54px;
   /* identical to box height, or 112% */
   letter-spacing: -0.01em;
}

.h1-headline-regular {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 36px;
   line-height: 48px;
   /* identical to box height, or 133% */
}

.h1-headline-light {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 300;
   font-size: 36px;
   line-height: 42px;
   /* identical to box height, or 117% */
}

.h2-headline {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 300;
   font-size: 26px;
   line-height: 36px;
   /* identical to box height, or 138% */
}

.h2-mono {
   font-family: "Roboto Mono";
   font-style: normal;
   font-weight: 300;
   font-size: 30px;
   line-height: 18px;
   /* identical to box height, or 60% */
   letter-spacing: 0.05em;
}

.body-large-light {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 300;
   font-size: 16px;
   line-height: 22px;
   /* identical to box height, or 138% */
}

.body-large-regular {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 20px;
   /* identical to box height, or 125% */
}

.body-large-semibold {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 20px;
   /* identical to box height, or 125% */
}

.body-standard-regular {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   /* identical to box height, or 129% */
}

.body-standard-semibold {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 18px;
   /* identical to box height, or 129% */
}

.body-small-regular {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 16px;
   /* identical to box height, or 133% */
}

.body-small-semibold {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 600;
   font-size: 12px;
   line-height: 16px;
   /* identical to box height, or 133% */
}

.label-small-allcaps {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 12px;
   /* or 100% */
   letter-spacing: 0.03em;
   text-transform: uppercase;
}

.label-mono {
   font-family: "Roboto Mono";
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 18px;
   /* identical to box height, or 150% */
   letter-spacing: 0.1em;
}

.label-xsmall {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 500;
   font-size: 11px;
   line-height: 12px;
   /* or 109% */
   letter-spacing: 0.03em;
}

.label-xsmall-allcaps {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 500;
   font-size: 10px;
   line-height: 12px;
   /* or 120% */
   letter-spacing: 0.03em;
   text-transform: uppercase;
}
